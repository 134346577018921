import { Ref, computed, ref } from "vue";
import { AuthControl } from "../authControl";
import { getResponseJSON } from "./serviceUtils";

export interface Service {
  isLoading: Ref<boolean>
  lastErrorMessage: Ref<string | undefined>
}

export class ServiceImpl {
  public loading = ref(0);
  public readonly lastErrorMessage = ref<string | undefined>();
  public readonly isLoading = computed<boolean>(() => (this.loading.value > 0));

  protected apiServer: string;
  protected authControl?: AuthControl;
  protected allowUnauthenticated?: boolean;

  constructor(
    apiServer: string,
    authControl?: AuthControl,
    allowUnauthenticated?: boolean
  ) {
    this.apiServer = apiServer;
    this.authControl = authControl;
    this.allowUnauthenticated = allowUnauthenticated;

    if (authControl) {
      authControl.onAuthStateChanged((isSignedIn) => {
        if (!isSignedIn) this.lastErrorMessage.value = undefined;
      });
    }
  };

  protected async getHeaders(forceRefresh?: boolean): Promise<{ [key: string]: any; }> {
    const headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    };

    if (this.authControl) {
      const idToken = await this.authControl.getIdToken(forceRefresh);
      if (!idToken) {

        if (this.allowUnauthenticated) {
          return headers;
        } else {
          throw new Error('No idToken');
        }
      }

      return {
        ...headers,
        'Authorization': `Bearer ${idToken}`
      };

    } else {
      return headers;
    }
  }

  protected getMode(): RequestMode | undefined {
    // Use fetch() default
    return undefined;
  }


  // TODO(tjohns): getUrl should return a URL
  private getUrl(path: string, searchParams?: URLSearchParams): string {

    const url = new URL(path, this.apiServer);
    if(searchParams) {
      searchParams.forEach((value, key) => url.searchParams.append(key, value));
    }
    return url.toString();

  }

  // TODO(tjohns): Figure out how to give 'transform' and the return better types than function and Promise<any>
  public async fetch(fetch: Function, transform?: Function): Promise<any> {

    this.lastErrorMessage.value = undefined;
    this.loading.value++;

    try {
      const response = await fetch();

      const responseJSON = await getResponseJSON(response);


      return transform ? transform(responseJSON) : undefined;

    } catch (error) {
      this.lastErrorMessage.value = (error as Error).message || "Something went wrong. Please try again later.";
      throw error;

    } finally {

      this.loading.value--;
    }
  }

  public async get(path: string, transform: Function = (() => {}), searchParams?: URLSearchParams, forceRefresh?: boolean): Promise<any> {
    return this.fetch(async () => fetch(this.getUrl(path, searchParams), {
      headers: await this.getHeaders(forceRefresh),
      mode: this.getMode()
    }), transform);
  }

  public async patch(path: string, body: any, transform: Function = (() => {})): Promise<any> {
    return this.fetch(async () => fetch(this.getUrl(path), {
      method: "PATCH",
      headers: await this.getHeaders(),
      body: JSON.stringify(body)
    }), transform);
  }

  public async post(path: string, body: any, transform: Function = (() => {})): Promise<any> {
    return this.fetch(async () => fetch(this.getUrl(path), {
      method: "POST",
      headers: await this.getHeaders(),
      body: JSON.stringify(body)
    }), transform);
  }

  public async delete(path: string): Promise<any> {
    return this.fetch(async () => fetch(this.getUrl(path), {
      method: "DELETE",
      headers: await this.getHeaders(),
    }));
  }

}
