import { Ref, computed, ref } from "vue";
import { AuthControl } from "../authControl";
import { Profile, ProfileUserProps } from "../models/profile";
import { ResponseJSON } from "./serviceUtils";
import { Service, ServiceImpl } from "./service";

export type CreateProfileParams = {
  previewOptIn?: boolean
};
export interface ProfilesService extends Service {
  populateProfile(forceRefresh?: boolean): Promise<void>;

  createProfile(params?: CreateProfileParams): Promise<Profile>;
  getProfile(): Promise<Profile>;
  updateProfile(profileId: string, profile: ProfileUserProps): Promise<Profile>;

  getStripeSessionUrl(): Promise<string>;
  addStripeSubscription(profileId: string, stripeSessionId: string): Promise<Profile>;
  cancelSubscription(profileId: string): Promise<Profile>;

  isEarlyAdopter: Ref<boolean>
  isAdmin: Ref<boolean>
  emailVerified: Ref<boolean>
  hasSubscription: Ref<boolean>
  enabledFeatures: Ref<Array<string>>;
  showDynamicRoadtripMaps: Ref<boolean>
  /**
   * @deprecated - use the composable (useProfile) to access 'profile')
   */
  profile: Ref<Profile | undefined>
}

export const EXPERIMENTAL_FEATURES = [
  "ERTPStop-deleteStop",
  "ERTPStop-navigateToStop",
  "ERTPStop-editStop",
  "ERTPActions-includePreview",
  "ERTPWeather-includeForecast"
];

  
type ProfilesResponseJSON = ResponseJSON & {
  profiles?: Array<Profile>
}

type StripeSessionURLResponseJSON = ResponseJSON & {
  stripeSessionUrl?: string
}

function stripeSessionUrlFromResponseJSON (responseJSON: StripeSessionURLResponseJSON): string {
  if (!responseJSON.stripeSessionUrl) throw Error('No Stripe Session URL in response.');

  return responseJSON.stripeSessionUrl;
}


function profileFromResponseJSON (responseJSON: ProfilesResponseJSON): Profile {
  if (responseJSON.profiles) {
    // returned an array, but we only care about the first one
    return responseJSON.profiles[0];
  } else {
    delete responseJSON.status;
    return responseJSON as Profile;
  }
}

export class ProfilesServiceImpl 
  extends ServiceImpl 
  implements ProfilesService {

  /**
   * @deprecated - use the composable (useProfile) to access 'profile')
   */
  public readonly profile = ref<Profile>();
 
  public readonly isEarlyAdopter = computed(() => {
    return (this.profile.value?.roles || []).includes("earlyadopter")
  });
  public readonly isAdmin = computed(() => {
    return (this.profile.value?.roles || []).includes("admin")
  });
  public readonly emailVerified = computed(() => {
    return (!!this.profile.value?.emailVerified)
  });
  public readonly hasSubscription = computed(() => {
    return (!!this.profile.value?.subscriptions && this.profile.value?.subscriptions.length > 0)
  })
  public readonly showDynamicRoadtripMaps = computed(() => {
    if (!this.hasSubscription.value) return false; // No subscription, no choice
    if (!this.profile.value) return false; // No profile, no choice
    return !!this.profile.value.showDynamicRoadtripMaps;
  });
  public readonly enabledFeatures = computed(() => {
    return this.profile.value?.enabledFeatures || []; 
  });
  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)

    authControl.onAuthStateChanged((isSignedIn) => {
      if (isSignedIn) {
        this.populateProfile(true);
      } else {
        this.profile.value = undefined;
      }
    });
  };

  public async populateProfile(forceRefresh?: boolean): Promise<void> {

    try {
      this.loading.value++;
      this.profile.value = await this.getProfile(forceRefresh);
    } catch (error) {
      this.lastErrorMessage.value = (error as Error).message || "Something went wrong. Please try again later.";
      throw error;
    } finally {
      this.loading.value--;
    }
  }

  async createProfile(params: CreateProfileParams = {}): Promise<Profile> {
    return await this.post(`/v1/profiles`, params, profileFromResponseJSON);
  }

  async getProfile(forceRefresh?: boolean): Promise<Profile> {
    return await this.get(`/v1/profiles`, profileFromResponseJSON, undefined, forceRefresh);
  }

  async updateProfile(profileId: string, profile: ProfileUserProps): Promise<Profile> {
    return await this.patch(`/v1/profiles/${profileId}`, {profile}, profileFromResponseJSON);
  }

  async getStripeSessionUrl(): Promise<string> {
    return await this.post(`/stripe/create-checkout-session`, {}, stripeSessionUrlFromResponseJSON)
  }

  async addStripeSubscription(profileId: string, stripeSessionId: string): Promise<Profile> {
    return await this.patch(`/v1/profiles/${profileId}`, {stripeSessionId}, profileFromResponseJSON);
  }
  
  async cancelSubscription(profileId: string): Promise<Profile> {
    return await this.patch(`/v1/profiles/${profileId}`, {profile: {subscriptions: []}}, profileFromResponseJSON);
  }

};